import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor() { }

  get baseUrl() {
    const parsedUrl = new URL(window.location.href);
    return  parsedUrl.origin;
  }
}
