import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {RouterModule, Routes} from '@angular/router';
import {OAuthRedirectViewComponent} from './o-auth-redirect-view/o-auth-redirect-view.component';
import {AngularFireFunctionsModule, REGION} from '@angular/fire/functions';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {canActivate, redirectLoggedInTo, redirectUnauthorizedTo} from '@angular/fire/auth-guard';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCardModule} from '@angular/material/card';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatListModule} from '@angular/material/list';
import {PrivacyViewComponent} from './privacy-view/privacy-view.component';
import {MatTableModule} from '@angular/material/table';
import {LandingViewComponent} from './landing-view/landing-view.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import Bugsnag from '@bugsnag/js';
import {BugsnagErrorHandler} from '@bugsnag/plugin-angular';
import {FeedbackAndSupportViewComponent} from './feedback-and-support-view/feedback-and-support-view.component';
import {LoginViewComponent} from './login-view/login-view.component';
import {FaqViewComponent} from './faq-view/faq-view.component';
import {SharedModule} from './shared/shared.module';
import {ManualRefreshViewComponent} from './manual-refresh-view/manual-refresh-view.component';
import {PricingViewComponent} from './pricing-view/pricing-view.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {FormsModule} from '@angular/forms';

const routes: Routes = [
  {
    path: '',
    component: LandingViewComponent
  },
  {
    path: 'login',
    component: LoginViewComponent,
    ...canActivate(() => redirectLoggedInTo(['app', 'select']))
  },
  {
    path: 'logged-in',
    component: OAuthRedirectViewComponent,
    ...canActivate(() => redirectLoggedInTo(['app', 'select']))
  },
  {
    path: 'privacy',
    component: PrivacyViewComponent
  },
  {
    path: 'support',
    component: FeedbackAndSupportViewComponent
  },
  {
    path: 'faq',
    component: FaqViewComponent
  },
  {
    path: 'manual-refresh',
    component: ManualRefreshViewComponent
  },
  {
    path: 'pricing',
    component: PricingViewComponent
  },
  {
    path: 'app',
    loadChildren: () => import('./logged-in/logged-in.module').then(m => m.LoggedInModule),
    ...canActivate((next, state) => redirectUnauthorizedTo('/login?redirectTo=' + encodeURIComponent(state.url)))
  },
  {
    path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    ...canActivate((next, state) => redirectUnauthorizedTo('/login?redirectTo=' + encodeURIComponent(state.url)))
  },
];

Bugsnag.start({
  apiKey: '67cb5ba59190441cb431fd611aa5f5ac',
  autoTrackSessions: false,
  collectUserIp: false
});

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function errorHandlerFactory(): BugsnagErrorHandler {
  return new BugsnagErrorHandler();
}

@NgModule({
  declarations: [
    AppComponent,
    OAuthRedirectViewComponent,
    PrivacyViewComponent,
    LandingViewComponent,
    FeedbackAndSupportViewComponent,
    LoginViewComponent,
    FaqViewComponent,
    ManualRefreshViewComponent,
    PricingViewComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {anchorScrolling: 'enabled'}),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireFunctionsModule,
    AngularFirestoreModule, // needed for current plan on pricing view
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatCardModule,
    MatTooltipModule,
    MatListModule,
    MatTableModule,
    MatSidenavModule,
    SharedModule,
    MatButtonToggleModule,
    FormsModule
  ],
  providers: [
    {provide: ErrorHandler, useFactory: errorHandlerFactory},
    /**
     * There are two (meaningful) possibilities for functions deployment:
     * a) deploy functions close to DB: europe-west-1
     * b) Save CORS-request: (hosting redirect to) us-central1
     */
    {provide: REGION, useValue: 'europe-west1'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
