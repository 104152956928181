import {ValiPodEnvironment} from './valiPodEnvironment';

export const environment: ValiPodEnvironment = {
  production: true,
  podioClientId: 'vali-podio',
  features: {
    pricing: true
  },
  firebase: {
    apiKey: 'AIzaSyA7FbkqMNICFF__XnQZYl6W0sm4nhVpQDc',
    authDomain: 'podio-validation.firebaseapp.com',
    databaseURL: 'https://podio-validation.firebaseio.com',
    projectId: 'podio-validation',
    storageBucket: 'podio-validation.appspot.com',
    messagingSenderId: '549913506014',
    appId: '1:549913506014:web:e0a840223caefb0013524f',
    measurementId: 'G-XX1VN04WMP'
  }
};
