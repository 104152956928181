<div class="pv-main pv-copy">
  <div class="pv-margin">
    <h1 class="pv-page-title">FAQs</h1>
    <p>
      Can't find an answer to your question?<br>
      Please check our
      <a class="pv-link" href="https://productific.com/@ValiPod" target="_blank" rel="noopener">
        roadmap / feature page
        <mat-icon class="pv-muted" [inline]="true">launch</mat-icon>
      </a>
      or contact us:
      <a class="pv-link" href="mailto:info@vali-pod.io">info@vali-pod.io</a>
    </p>
  </div>

  <div class="faq-section">
    <h2 id="field-position">How to change the position of the validation field in Podio?</h2>
    <p>
      Currently, the validation field, that shows the validation messages, is always created at the second position from the top.
      Intuitively, some might want the field to be the first/topmost field.
      But as the first field is used as the item title at several places in Podio, this is not recommended.
    </p>
    <p>
      You can move the field to any other position in Podio by using the regular
      <a class="pv-link" href="https://help.podio.com/hc/en-us/articles/201019578-Modifying-app-templates" target="_blank" rel="noopener">
        "modify template" method
        <mat-icon class="pv-muted" [inline]="true">launch</mat-icon>
      </a>
      within Podio.
      If you moved the field, the position won't be changed when publishing changes to the validation.
      When the validation is deleted, a newly created validation is considered a new field, hence is again created at second position.

      <img id="field-position-image" src="assets/move-podio-field.png" alt="Screenshot of moving a field in Podio" class="pv-box-shadow">
    </p>
  </div>

  <div class="faq-section">
    <h2 id="rename-label">How can I change the label of the validation field?</h2>
    <p>
      You can change the label of the field directly in Podio via
      <a class="pv-link" href="https://help.podio.com/hc/en-us/articles/201019578-Modifying-app-templates" target="_blank" rel="noopener">
        "modify template"
        <mat-icon class="pv-muted" [inline]="true">launch</mat-icon>
      </a>.
      As long as you do not temper with the calc field contents, everything will work just fine.
    </p>
  </div>

  <div class="faq-section">
    <h2 id="reference-fields">How do relationship/reference fields work?</h2>
    <p>
      Please note, that currently only outgoing references are supported.
      This means when when you have a field in app A that references items in app B,
      you can create a validation in A that is based on the referenced item(s) in B,
      but you cannot create a validation in B that is based on referencing items in A.
    </p>
    <app-ref-help></app-ref-help>
  </div>

  <div class="faq-section">
    <h2 id="compare-two-fields">How to compare two fields to each other?</h2>
    <p>
      This is not possible yet, but in our feature list.
      If you'd like this feature, please vote and leave a comment on the
      <a class="pv-link" href="https://productific.com/@ValiPod/Compare-two-fields-to-each-other-o6lw" target="_blank" rel="noopener">feature page</a>.
    </p>
  </div>

  <div class="faq-section">
    <h2 id="validate-attachments">How to validate attachments/files?</h2>
    <p *ngIf="!v1">
      This is not possible yet, but in our feature list.
      If you'd like this feature, please vote and leave a comment on the
      <a class="pv-link" href="https://productific.com/@ValiPod/Support-attachments-ZJRl" target="_blank" rel="noopener">feature page</a>.
    </p>
    <ng-container *ngIf="v1">
      <p>
        Rules for attachments/files can be configured similar to rules for regular fields.
        You need to select "Attached files (attachments)" in the field input, then operations and values can be configured.
      </p>
      <p>
        Please note, that this feature is only available in the paid plans
        (see <a routerLink="/pricing" class="pv-link">pricing</a>).
      </p>
      <p>
        Example configuration:
        <img id="configure-attachment-rules" src="assets/configure-file-validations.png" alt="Screenshot of rules for attachments" class="pv-box-shadow">
      </p>
    </ng-container>

  </div>

  <div class="faq-section">
    <h2 id="max-rules">Is there a maximum number of validation rules I can configure?</h2>
    <p *ngIf="!v1">
      Currently, {{maxRules}} rules can be configured per app (spread over multiple validations).
      This limit might be removed or increased in the future, once we are sure that a large number of rules don't cause any trouble.
    </p>
    <p *ngIf="!v1">
      If you'd need an increased limit right now, please let us know:
      <a class="pv-link" href="mailto:info@vali-pod.io">info@vali-pod.io</a>
    </p>
    <p *ngIf="v1">
      In the free plan, {{maxRules}} rules can be configured per app
      (spread over multiple validations).<br>
      This should be enough for most most setups.
    </p>
    <p *ngIf="v1">
      With the paid plans, this limit is increased to {{maxRulesPaid}} rules.<br>
      If you'd need even more, please let us know:
      <a class="pv-link" href="mailto:info@vali-pod.io">info@vali-pod.io</a>
    </p>
  </div>

</div>
