/* eslint-disable no-shadow */
import {PApp, PField, PFieldType, POption, POrg} from './podio-models';

/**
 * BE AWARE: Creating fields with given external id is not possible:
 * https://stackoverflow.com/questions/52101101/podio-app-create-not-respecting-field-external-id
 */
export const VALIDATION_EXTERNAL_FIELD_ID = 'validation-vali-podio';
export const VALIDATION_LABEL = 'Validation (vali-pod.io)';
export const VALIDATION_IDENTIFIER = 'validation by vali-pod.io (do not modify)';

/**
 * @see https://developer.paddle.com/webhook-reference/subscription-alerts/subscription-created
 */
export interface Subscription {
    subscriptionId: number;
    /** = subscription_plan_id */
    productId: number;
    quantity: number;
    status: 'ACTIVE' | 'TRIALING' | 'PAST_DUE' | 'PAUSED' | 'CANCELLED';
    /** unset if cancelled */
    cancelUrl?: string;
    /** unset if cancelled */
    updateUrl?: string;
}

export function featureEnabled(s: UserSettings | undefined, appUrl: string, feature: 'files'): boolean {
    return s?.premiumAll === true || (s?.premiumOrgs?.some(o => appUrl.startsWith(o.url)) ?? false);
}

export const FILES_FIELD_LABEL = 'track files for vali-pod';

export interface UserSettings {
    maxRules: number;
    subscription?: Subscription;
    /** especially 'url' must be set! */
    premiumOrgs?: POrg[];
    premiumAll?: boolean;
    /** number of paid orgs in standard plan */
    maxPremiumOrgs: number;
}

export type PlanType = 'FREE' | 'STANDARD' | 'PREMIUM';
// The metadata you want to attach to subscriptions, to link them to your application entities
export type PaddleMetadata = { userId: string }

export function getPlanType(settings?: UserSettings): PlanType {
    if (settings?.premiumAll) {
        return 'PREMIUM';
    } else if ((settings?.maxPremiumOrgs ?? 0) > 0) {
        return 'STANDARD';
    } else {
        return 'FREE';
    }
}

export const PLAN_ID_STANDARD_MONTHLY = 643322;
export const PLAN_ID_STANDARD_YEARLY = 643323;
export const PLAN_ID_PREMIUM_MONTHLY = 643324;
export const PLAN_ID_PREMIUM_YEARLY = 643325;

export function isMonthly(settings?: UserSettings): boolean {
    const productId = settings?.subscription?.productId;
    return !!(productId && [PLAN_ID_STANDARD_MONTHLY, PLAN_ID_PREMIUM_MONTHLY].indexOf(productId) >= 0);
}


export const MAX_RULES_FREE_PLAN = 20; // TODO reduce for free plan to 10
export const MAX_RULES_PAID_PLAN = 50;
export const DEFAULT_USER_SETTINGS: UserSettings = {
    maxRules: MAX_RULES_FREE_PLAN,
    maxPremiumOrgs: 0
}

export type GroupOrRule = Group | Rule;

export interface DeploymentInfo {
    appId: number;
    isDeployed: boolean;
    deployedTs?: string;
    /** `true` whenever the validation does not match the deployed version. */
    validationChanged: boolean;
    validationChangedTs?: string;
}

export interface DeploymentInfoMap {
    [appId: string]: DeploymentInfo;
}

export interface RefAppLoadingError {
    error: string;
    app: PApp;
}

/** docs in collections '/podio-structure/[USER_ID]/apps/' */
export interface AppDoc {
    /** when `true` all apps directly referenced are stored in DB (except for errors - see `referencedAppsLoadErrors`) */
    referencedAppsLoaded?: boolean;
    referencedAppsLoadErrors?: { [appId: number]: RefAppLoadingError };
    app: PApp;
}

/** docs in collections '/podio-structure/[USER_ID]' */
export interface StructureDoc {
    loading: boolean;
    warnings?: string[];
    orgs?: POrg[];
    deploymentInfo?: DeploymentInfoMap;
}


export interface Validation {
    id: string;
    rank: number;
    app_id: number;
    errorMessage: string;
    validationType: 'ERROR' | 'WARNING'
    rules: Group;
}

export interface Group {
    type: 'GROUP';
    combination: 'AND' | 'OR';
    children: GroupOrRule[];
}

export enum InputType {
    /** eg for 'is_empty' */
    NONE,
    DATE,
    NUMBER,
    PERCENT,
    TEXT,
    SELECT,
    SELECT_MULTI,
    DURATION,
    SELECT_APP
}

export enum Operation2 {
    is_empty = 'empty',
    is_not_empty = 'not_empty',
    equals = '==',
    not_equals = '!=',
    does_contain = 'does_contain',
    does_not_contain = 'does_not_contain',
    gt = '>',
    gte = '>=',
    lt = '<',
    lte = '<=',
    ref_at_least_one = 'ref_at_least_one',
    ref_all = 'ref_all',
    ref_single = 'ref_single',
    ref_none = 'ref_none'
}

export function getAppIds(structure: StructureDoc, orgId: number): number[] {
    return structure.orgs?.find(o => orgId === orgId)?.spaces?.reduce((prev, space) => [...prev, ...space.apps?.map(a => a.app_id) ?? []], [] as number[]) ?? [];
}

export function isRefOp(f: Field | undefined | null, op: Operation2 | undefined | null): op is Operation2.ref_all | Operation2.ref_at_least_one | Operation2.ref_single {
    if (f?.type === CustomFieldType.attachments) {
        return false;
    }
    return op === Operation2.ref_at_least_one || op === Operation2.ref_all || op === Operation2.ref_single;
}

export function isReferenceMatch(o: any): o is ReferenceMatch {
    return !!o && typeof o === 'object' && 'type' in o && o.type === 'REFERENCE_MATCH'
}

export function isMultiItemReference(field: PField): boolean {
    return field.config.settings.multiple ?? false;
}

export function isMultiAppReference(field: PField | null): boolean {
    return (field?.config.settings.referenced_apps?.length ?? 0) > 1;
}

export function hasFileRef(rules: GroupOrRule): boolean {
    return rules.type === 'GROUP' ? rules.children.some(c => hasFileRef(c)) : rules.field?.type === CustomFieldType.attachments;
}

export function getExistingValidationField(app: PApp | undefined | null): PField | undefined {
    return app?.fields
        ?.filter(f => f.status !== 'deleted')
        .find(f => (f.config?.settings?.script?.indexOf(VALIDATION_IDENTIFIER) ?? -1) >= 0);
}

export interface ReferenceMatch {
    type: 'REFERENCE_MATCH'
    app?: PApp,
    rule?: Rule
}

export type CompareValue = number | string | POption | POption[] | ReferenceMatch | null;


export enum CustomFieldType {
    attachments = 'attachments',
}


/** artificial "field" for attachments */
export interface CustomField {
    type: CustomFieldType,
    label: string,
    status: 'active'
    field_id: number;
}

export type Field = PField | CustomField;

export interface Rule {
    type: 'RULE';
    field?: Field;
    operation?: Operation2;
    compareValue?: CompareValue;
}

export function getInputType(fieldType: Field, operation: Operation2): InputType {
    switch (operation) {
        case Operation2.is_empty:
        case Operation2.is_not_empty:
            return InputType.NONE;
        case Operation2.equals:
        case Operation2.not_equals:
            switch (fieldType.type) {
                case PFieldType.text:
                    return InputType.TEXT;
                case PFieldType.number:
                case PFieldType.money:
                    return InputType.NUMBER;
                case PFieldType.category:
                    return fieldType.config.settings.multiple ? InputType.SELECT_MULTI : InputType.SELECT;
                default:
                    throw new Error(`[getInputType] unexpected combination fieldType=${fieldType.type} and operation=${operation}`);
            }
        case Operation2.does_contain:
        case Operation2.does_not_contain:
            switch (fieldType.type) {
                case PFieldType.text:
                    return InputType.TEXT;
                case PFieldType.category:
                    return InputType.SELECT;
                default:
                    throw new Error(`[getInputType] unexpected combination fieldType=${fieldType.type} and operation=${operation}`);
            }
        case Operation2.gt:
        case Operation2.gte:
        case Operation2.lt:
        case Operation2.lte:
            switch (fieldType.type) {
                case CustomFieldType.attachments:
                case PFieldType.number:
                case PFieldType.money:
                    return InputType.NUMBER;
                case PFieldType.progress:
                    return InputType.PERCENT;
                case PFieldType.date:
                    return InputType.DATE;
                case PFieldType.duration:
                    return InputType.DURATION;
                default:
                    throw new Error(`[getInputType] unexpected combination fieldType=${fieldType.type} and operation=${operation}`);
            }
        case Operation2.ref_at_least_one:
        case Operation2.ref_all:
        case Operation2.ref_single:
            switch (fieldType.type) {
                case PFieldType.app:
                    return InputType.SELECT_APP
                case CustomFieldType.attachments:
                    return InputType.TEXT;
                default:
                    throw new Error(`[getInputType] unexpected combination fieldType=${fieldType.type} and operation=${operation}`);
            }
        case Operation2.ref_none:
            switch (fieldType.type) {
                case CustomFieldType.attachments:
                    return InputType.TEXT;
                default:
                    throw new Error(`[getInputType] unexpected combination fieldType=${fieldType.type} and operation=${operation}`);
            }
        default:
            throw new Error(`[getInputType] unexpected operation=${operation}`);
    }
}

export function getOperations(fieldType: Field): Operation2[] {
    return Object.values(Operation2)
        .filter((o: Operation2) => {
            switch (o) {
                case Operation2.is_empty:
                case Operation2.is_not_empty:
                    return ([PFieldType.calculation, PFieldType.app, PFieldType.image, PFieldType.media, PFieldType.file] as (PFieldType | CustomFieldType)[]).indexOf(fieldType.type) < 0;
                case Operation2.equals:
                case Operation2.not_equals:
                    return ([PFieldType.text, PFieldType.number, PFieldType.money, PFieldType.category] as (PFieldType | CustomFieldType)[]).indexOf(fieldType.type) >= 0;
                case Operation2.does_contain:
                case Operation2.does_not_contain:
                    return PFieldType.text === fieldType.type
                        || fieldType.type === PFieldType.category && fieldType.config.settings.multiple;
                case Operation2.gt:
                case Operation2.gte:
                case Operation2.lt:
                case Operation2.lte:
                    return [PFieldType.number, PFieldType.date, PFieldType.duration, PFieldType.money, PFieldType.progress, CustomFieldType.attachments].indexOf(fieldType.type) >= 0;
                case Operation2.ref_at_least_one:
                case Operation2.ref_all:
                    return CustomFieldType.attachments === fieldType.type || (PFieldType.app === fieldType.type && isMultiItemReference(fieldType));
                case Operation2.ref_single:
                    return PFieldType.app === fieldType.type && !isMultiItemReference(fieldType);
                case Operation2.ref_none:
                    return CustomFieldType.attachments === fieldType.type;
                default:
                    throw new Error(`[getOperations] unexpected fieldType=${fieldType.type}`);
            }
        });
}
