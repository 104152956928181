import {ChangeDetectionStrategy, Component} from '@angular/core';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-feedback-and-support-view',
  templateUrl: './feedback-and-support-view.component.html',
  styleUrls: ['./feedback-and-support-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedbackAndSupportViewComponent {

  v1 = environment.features.pricing;

}
