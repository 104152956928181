<p>
  Reference fields (also called "App fields") introduce some complexity for validations.
  Let's get some context for you, to use them in the right way, so that your validations actually do what you expect.
</p>
<p>
  First, notice that reference fields can have different configurations:
</p>
<dl class="pv-dl">
  <dt>Single app</dt>
  <dd>Only items of one specific app can be referenced.</dd>
  <dt>Multi app</dt>
  <dd>
    Items of at least two different apps can be referenced.<br>
    Validation must be <strong>configured for one of the apps</strong>.<br>
    (Of course you can add additional rules for different apps and combine them by "and"/"or".)
  </dd>
  <dt>Single item</dt>
  <dd>At most one item can be referenced.</dd>
  <dt>Multi item</dt>
  <dd>
    An arbitrary number of items can be referenced.<br>
    Validation can either test/match <strong>all items</strong> or <strong>at least one item</strong>.<br>
    Note that when testing for "all items", this as well holds true when no item is selected!
  </dd>
</dl>

<p>These configurations can occur in the following combinations, where some combinations currently have some restrictions regarding possible validations:</p>

<table class="pv-margin-b pv-table pv-table-vtop">
  <thead>
  <tr>
    <th></th>
    <th>Single app</th>
    <th>Multi app</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <th>Single item</th>
    <td>
      <p>0 or 1 item of one specific app.</p>
      <p class="mat-body-strong pv-color-primary" *ngIf="is(false, false)">The field "{{field?.label}}" is of this type!</p>
    </td>
    <td>
      <p>0 or 1 item of one of multiple apps.</p>
      <p class="mat-body-strong pv-color-primary" *ngIf="is(false, true)">The field "{{field?.label}}" is of this type!</p>
      Restrictions:
      <ul class="pv-margin-t-small">
        <li> The operation "is empty" is not possible.</li>
      </ul>
    </td>
  </tr>
  <tr>
    <th>Multi item</th>
    <td>
      <p>0, 1 or more items of one specific app.</p>
      <p class="mat-body-strong pv-color-primary" *ngIf="is(true, false)">The field "{{field?.label}}" is of this type!</p>
    </td>
    <td>
      <p>0, 1 or more items of each of multiple apps.</p>
      <p class="mat-body-strong pv-color-primary" *ngIf="is(true, true)">The field "{{field?.label}}" is of this type!</p>
      Restrictions:
      <ul class="pv-margin-t-small">
        <li>The operation "is empty" is not possible.</li>
        <li>
          When matching "all referenced items", the validation only works when no items from other apps (than the selected) are referenced.
        </li>
      </ul>
    </td>
  </tr>
  </tbody>
</table>

