import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AngularFireFunctions} from '@angular/fire/functions';
import {AngularFireAuth} from '@angular/fire/auth';
import {fromPromise} from 'rxjs/internal-compatibility';
import {UrlService} from '../shared/url.service';

@UntilDestroy()
@Component({
  selector: 'app-o-auth-redirect-view',
  templateUrl: './o-auth-redirect-view.component.html',
  styleUrls: ['./o-auth-redirect-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OAuthRedirectViewComponent implements OnInit {

  private loginFunction = this.fns.httpsCallable('login');

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private urlService: UrlService,
              private fns: AngularFireFunctions,
              private auth: AngularFireAuth) {
  }

  ngOnInit(): void {
    this.auth.user.pipe(
      filter(user => !!user),
      untilDestroyed(this),
      map(() => this.activatedRoute.snapshot.queryParamMap.get('redirectTo') ?? '/app/select'),
      map(url => decodeURIComponent(url)) // duplicated encoding for redirectTo url to assure "?" is handled correctly:
    ).subscribe(url => this.router.navigateByUrl(url));

    this.activatedRoute.queryParamMap.pipe(
      map(param => param.get('code')),
      filter(code => !!code),
      take(1),
      switchMap(code => this.loginFunction({podioLoginCode: code, redirectTo: `${this.urlService.baseUrl}/logged-in`})),
      map(response => response.token),
      switchMap(token => fromPromise(this.auth.signInWithCustomToken(token))),
      untilDestroyed(this)
    ).subscribe();

  }

}
