import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'app-privacy-view',
  templateUrl: './privacy-view.component.html',
  styleUrls: ['./privacy-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyViewComponent {

}
