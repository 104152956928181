import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {UrlService} from '../shared/url.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-landing-view',
  templateUrl: './landing-view.component.html',
  styleUrls: ['./landing-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingViewComponent implements OnInit {

  redirectUrl = this.urlService.baseUrl + '/logged-in';
  podioClientId = environment.podioClientId;
  v1 = environment.features.pricing;
  loggedIn$: Observable<boolean>;

  constructor(private urlService: UrlService, private auth: AngularFireAuth) {
  }

  ngOnInit(): void {
    this.loggedIn$ = this.auth.user.pipe(map(u => !!u));
  }

}
