<div class="hero">
  <div class="hero-image">
    <div class="hero-text">
      <h1 class="mat-display-4">Vali Pod</h1>
      <h2 class="mat-display-2">
        Validations for Podio&reg; made easy!
      </h2>
      <div class="partner-container">
        <img src="assets/podio_authorizedpartner-01-2-small.png" alt="Podio authorized partner">
      </div>
      <a *ngIf="(loggedIn$ | async) !== true" [href]="'https://podio.com/oauth/authorize?client_id='+podioClientId+'&redirect_uri='+redirectUrl+'&scope='"
         mat-raised-button color="primary" class="pv-button-large register-now">
        Register for free!
      </a>
    </div>
  </div>
</div>

<div class="text pv-copy">
  <div class="section">
    <div class="icon">
      <mat-icon [inline]="true">help</mat-icon>
    </div>
    <div class="info">
      <h2>What is this?</h2>
      <p>
        Vali Pod lets you <span class="mat-body-strong">configure validations</span>
        for your <a href="https://www.podio.com" target="_blank" rel="noopener" class="pv-link">Podio&reg;</a> apps.
      </p>
      <p>
        Validations check if the values of multiple fields fit your configured criteria.
        In case the criteria are met (i.e. the item is invalid), a validation message is shown to the user in the specific item.
      </p>
      <p>This helps you to <span class="mat-body-strong">keep your data consistent and clean!</span></p>
    </div>
  </div>

  <div class="section">
    <div class="icon">
      <mat-icon [inline]="true">send</mat-icon>
    </div>
    <div class="info">
      <h2>How do I get started?</h2>
      <p class="pv-margin-t pv-margin-b">
        It's just four steps to your first working validation:
      </p>
      <!--https://commons.wikimedia.org/wiki/File:Pointing_hand_cursor_vector.svg-->
      <div class="get-started-steps pv-margin-b">
        <mat-card class="get-started-card">
          <mat-card-title>1. Log in</mat-card-title>
          <img mat-card-image src="assets/steps-1-log-in-sqare.png" alt="Log in">
          <mat-card-content>
            <p>Log in with your Podio account (make sure to have edit rights on the relevant workspaces).</p>
          </mat-card-content>
        </mat-card>
        <mat-card class="get-started-card">
          <mat-card-title>2. Select app</mat-card-title>
          <img mat-card-image src="assets/steps-2-select-app-square.png" alt="select app">
          <mat-card-content>
            <p>Select the Podio app you want to create a validation for.</p>
          </mat-card-content>
        </mat-card>
        <mat-card class="get-started-card">
          <mat-card-title>3. Configure &amp; publish validation</mat-card-title>
          <img mat-card-image src="assets/steps-4-publish-validation-square.png" alt="configure and publish">
          <mat-card-content>
            <p>Configure a validation message and the conditions.</p>
            <p>Publish the validation to Podio the podio app (without publishing the validation is only a personal draft).</p>
          </mat-card-content>
        </mat-card>
        <mat-card class="get-started-card">
          <mat-card-title>4. Check result</mat-card-title>
          <img mat-card-image src="assets/steps-5-result-in-podio-square.png" alt="check result in podio">
          <mat-card-content>
            <p>Check that the validation works as intended, by editing an item in the Podio app for which the validation was published.</p>
          </mat-card-content>
        </mat-card>
      </div>
      <p class="pv-margin-t">
        If you encounter any problems or need specific features, please <a routerLink="/support" class="pv-link mat-body-strong">contact us</a>!
      </p>
    </div>
  </div>

  <div class="section">
    <div class="icon">
      <mat-icon [inline]="true">person_outline</mat-icon>
    </div>
    <div class="info">
      <h2>Why would I need this?</h2>
      <p>
        For many apps exists some <span class="mat-body-strong">business logic</span> that governs how different fields of an item relate to each other.
        Even the most disciplined <span class="mat-body-strong">people tend to miss some of these things</span> from time to time.
        Then your data becomes inconsistent, which, later on,
        <span class="mat-body-strong">might lead to wrong business decisions and increased cost/time</span> to straighten the data.
      </p>
      <p>
        <span class="mat-body-strong">For example</span> you might want to assure every customer (in your customer app) has either “phone” or ”email” set,
        when in state “active”.
        Whenever a user removes the email or sets the status to active, you want to display a warning if no email/phone is set.
      </p>
    </div>
  </div>

  <div class="section">
    <div class="icon">
      <mat-icon [inline]="true">build</mat-icon>
    </div>
    <div class="info">
      <h2>How does it work?</h2>
      <!--TODO graphics?-->
      <p>
        The <span class="mat-body-strong">validation drafts are stored in your user account on vali-pod.io</span>.
        This means they are not shared with other users, even if they have access to the same Podio workspaces.
      </p>
      <p>
        When you publish the validations, a <span class="mat-body-strong">calculation field</span> is created in the
        corresponding Podio app, that displays the validation messages when the conditions are met.
        Of course, this <span class="mat-body-strong">field is visible to all users</span> with access to this Podio app.
      </p>
      <p>
        Note that in the future, the calculation field might be exchanged for a regular text field to support specific features.
      </p>
    </div>
  </div>

  <div class="section">
    <div class="icon">
      <mat-icon [inline]="true">done_all</mat-icon>
    </div>
    <div class="info">
      <h2>What kind of validations are supported?</h2>
      <p>
        Validations for any fields of an item are supported.
        Depending on the type of the field, different comparison operations are available.
      </p>
      Check for...
      <ul>
        <li>...<span class="mat-body-strong">empty field</span>:
          <ul>
            <li>All field types (except calculation, app/reference and image fields)</li>
            <li *ngIf="v1">
              <mat-icon [inline]="true" class="pv-inline-icon">fiber_new</mat-icon>
              Attachments/files (paid plans only)
            </li>
          </ul>
        </li>
        <li>...<span class="mat-body-strong">equality</span>:
          <ul>
            <li>Text</li>
            <li>Number</li>
            <li>Money</li>
            <li>Category</li>
          </ul>
        </li>
        <li>...<span class="mat-body-strong">contains</span>:
          <ul>
            <li>Text</li>
            <li>Category (multi selection)</li>
            <li *ngIf="v1">
              <mat-icon [inline]="true" class="pv-inline-icon">fiber_new</mat-icon>
              Attachment file name (paid plans only)
            </li>
          </ul>
        </li>
        <li>...<span class="mat-body-strong">greater/smaller than</span>:
          <ul>
            <li>Number</li>
            <li>Date</li>
            <li>Duration</li>
            <li>Money</li>
            <li>Progress</li>
            <li *ngIf="v1">
              <mat-icon [inline]="true" class="pv-inline-icon">fiber_new</mat-icon>
              Attachment file count (paid plans only)
            </li>
          </ul>
        </li>
        <li>...<span class="mat-body-strong">referenced item values</span>:
          <ul>
            <li>app/reference fields with single value/reference</li>
            <li>app/reference fields with multiple values/references</li>
            <li>app/reference fields with one reference target app</li>
            <li>app/reference fields with multiple reference target apps</li>
          </ul>
        </li>
      </ul>
      <p>
        The list of the operations will be extended in the future - if you miss something, please give some
        <a routerLink="/support" class="pv-link">feedback</a>!
      </p>
    </div>
  </div>

  <div class="section">
    <div class="icon">
      <mat-icon [inline]="true">attach_money</mat-icon>
    </div>
    <div class="info">
      <h2>What does it cost?</h2>
      <ng-container *ngIf="!v1">
        <p>
          This service is <span class="mat-body-strong">free while in “beta”</span> status.
          This means we are evaluating how it can provide greatest value for you.
        </p>
        <p>
          Of course eventually we will introduce paid options, to assure this service can be operated sustainably.
          Until then you could <span class="mat-body-strong">support us with <a routerLink="/support" class="pv-link">feedback</a>!</span>
        </p>
      </ng-container>
      <ng-container *ngIf="v1">
        <p>
          We have a generous "always free" plan, that should be fine for most setups.
        </p>
        <p>
          If you need more complex validation rules or rules for attachments/files, please check out our <a routerLink="/pricing" class="pv-link">paid plans</a>.
        </p>
      </ng-container>
    </div>
  </div>

  <div class="section">
    <div class="icon">
      <mat-icon [inline]="true">emoji_people</mat-icon>
    </div>
    <div class="info">
      <h2>Who built this?</h2>
      <div class="pv-flex founder-info">
        <img src="assets/profile_square.jpg" alt="Photo of founder" class="founder-image pv-box-shadow">
        <div>
          <p>
            Vali Pod is created by Daniel Schreiber
            (<a href="https://podio.com/users/1337749" target="_blank" rel="noopener" class="pv-link">Podio Profile
            <mat-icon class="pv-muted" [inline]="true">launch</mat-icon>
          </a>,
            <a href="https://twitter.com/danielschrei" target="_blank" rel="noopener" class="pv-link">Twitter
              <mat-icon class="pv-muted" [inline]="true">launch</mat-icon>
            </a>).
          </p>
          <p>
            He is also the founder of
            <a href="https://www.cloud-backup-for-podio.com" target="_blank" rel="noopener" class="pv-link">cloud-backup-for-podio.com</a>
            &mdash; a service for Podio backups, that is in operation since 2014.
          </p>
          <p>
            Made with
            <mat-icon [inline]="true" class="pv-inline-icon pv-warn">favorite</mat-icon>
            in Frankfurt, Germany.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="pv-margin pv-padding pv-flex pv-flex-col pv-flex-center">
    <a *ngIf="(loggedIn$ | async) !== true" [href]="'https://podio.com/oauth/authorize?client_id='+podioClientId+'&redirect_uri='+redirectUrl+'&scope='"
       mat-raised-button color="primary" class="pv-button-large">
      <img src="/assets/podio-icon.svg" class="podio-icon-large">
      Login with Podio
    </a>
  </div>

</div>

<div class="pv-muted pv-flex pv-margin footer">
  <a routerLink="/privacy" class="pv-link pv-text-link">Legal and Privacy Notice</a>
</div>
