import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MAX_RULES_FREE_PLAN, MAX_RULES_PAID_PLAN} from 'common-model/models';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-faq-view',
  templateUrl: './faq-view.component.html',
  styleUrls: ['./faq-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaqViewComponent {

  maxRules = MAX_RULES_FREE_PLAN;
  maxRulesPaid = MAX_RULES_PAID_PLAN;
  v1 = environment.features.pricing;

}
