import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {combineLatest, Observable, OperatorFunction} from 'rxjs';
import {environment} from '../../environments/environment';
import {UrlService} from '../shared/url.service';
import {filter, map, shareReplay, startWith, switchMap} from 'rxjs/operators';
import {AngularFireAuth} from '@angular/fire/auth';
import {
  DEFAULT_USER_SETTINGS,
  getPlanType,
  isMonthly,
  MAX_RULES_FREE_PLAN,
  MAX_RULES_PAID_PLAN,
  PlanType,
  UserSettings
} from 'common-model/models';
import {AngularFirestore} from '@angular/fire/firestore';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-pricing-view',
  templateUrl: './pricing-view.component.html',
  styleUrls: ['./pricing-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricingViewComponent implements OnInit {
  monthly = true;
  plan$: Observable<'FREE' | 'STANDARD' | 'PREMIUM' | 'NONE'>;
  planMonthly$: Observable<boolean>;

  redirectUrl = this.urlService.baseUrl + '/logged-in';
  podioClientId = environment.podioClientId;

  maxRulesFree = MAX_RULES_FREE_PLAN;
  maxRulesPaid = MAX_RULES_PAID_PLAN;


  constructor(private urlService: UrlService, private auth: AngularFireAuth, private afs: AngularFirestore) {
  }

  ngOnInit(): void {
    // duplication from UserSettingsService to keep app.module minimal..
    const settings$ = this.auth.user.pipe(
      filter(u => u !== null),
      switchMap(u => this.afs.doc<Partial<UserSettings>>(`user-settings/${u?.uid}`).valueChanges()),
      map(settings => ({...DEFAULT_USER_SETTINGS, ...settings})),
      shareReplay(1)
    );
    this.plan$ = settings$.pipe(
      map(settings => getPlanType(settings)),
      startWith('NONE') as OperatorFunction<PlanType, PlanType | 'NONE'>,
      shareReplay(1)
    );
    this.planMonthly$ = settings$.pipe(
      map(settings => isMonthly(settings)),
      startWith(false),
      shareReplay(1)
    );
    combineLatest([this.plan$.pipe(filter(p => p !== 'NONE')), this.planMonthly$]).pipe(
      untilDestroyed(this),
    ).subscribe(([p, m]) => this.monthly = m || p === 'FREE');
  }
}
