import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Observable} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from '../environments/environment';
import {debounceTime, filter, map, shareReplay, withLatestFrom} from 'rxjs/operators';
import {BreakpointObserver} from '@angular/cdk/layout';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MatSidenav, MatSidenavContainer} from '@angular/material/sidenav';
import firebase from 'firebase';
import {UrlService} from './shared/url.service';

/**
 * Layout:
 * Desktop: total>=1280, menu<=280, main>=1000 and main<=1200
 * Mobile: ?
 */
@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSidenav) drawer: MatSidenav;
  @ViewChild(MatSidenavContainer) drawerContainer: MatSidenavContainer;

  user$: Observable<firebase.User | null>;

  redirectUrl = this.urlService.baseUrl + '/logged-in';
  podioClientId = environment.podioClientId;
  sideNavOpen$: Observable<boolean>;
  isDesktop$: Observable<boolean>;
  featurePricing = environment.features.pricing;
  v1 = environment.features.pricing;

  constructor(private auth: AngularFireAuth,
              private router: Router,
              private breakpoints: BreakpointObserver,
              private urlService: UrlService) {
  }

  ngOnInit(): void {
    this.user$ = this.auth.user;
    this.isDesktop$ = this.breakpoints.observe(['(min-width: 1280px)']).pipe(
      map(r => r.matches),
      shareReplay(1)
    );
    this.sideNavOpen$ = this.isDesktop$;

    // try to workaround for nav overlapping content (only happens randomly...):
    this.isDesktop$.pipe(
      debounceTime(100),
      untilDestroyed(this),
    ).subscribe(() => this.drawerContainer.updateContentMargins());

    this.router.events.pipe(
      withLatestFrom(this.isDesktop$),
      filter(([a, isDesktop]) => !isDesktop && a instanceof NavigationEnd),
      untilDestroyed(this)
    ).subscribe(_ => this.drawer?.close());
  }

  ngAfterViewInit() {
    // try to workaround for nav overlapping content (only happens randomly...):
    setTimeout(() => this.drawerContainer.updateContentMargins(), 100);
  }

  async logout(): Promise<void> {
    await this.auth.signOut();
    await this.router.navigate(['']);
  }
}
