import {NgModule} from '@angular/core';
import {RefHelpComponent} from './ref-help/ref-help.component';
import {CommonModule} from '@angular/common';


/** Common components for app module and logged in module */
@NgModule({
  declarations: [
    RefHelpComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    RefHelpComponent
  ]
})
export class SharedModule {
}
