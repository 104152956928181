<div class="pv-margin pv-main">
  <h1 class="pv-page-title">Legal Disclosure and Privacy Notice</h1>
</div>
<div class="container pv-main">
  <mat-card class="pv-margin pv-flex-auto-grow">
    <mat-card-title>Contact</mat-card-title>
    <p>
      Telephone: +49-178-1972175<br/>
      E-Mail: <a class="pv-link" href="mailto:info@vali-pod.io">info@vali-pod.io</a><br/>
      Internet: <a class="pv-link" href="https://vali-pod.io">vali-pod.io</a>
    </p>
  </mat-card>

  <mat-card class="pv-margin pv-flex-auto-grow">
    <mat-card-title>Information in accordance with section 5 TMG</mat-card-title>
    <p>
      Daniel Schreiber<br/>Rohmerstr. 8<br/>60486 Frankfurt am Main, Germany<br/>
      Tax No: DE298228223
    </p>
  </mat-card>

  <mat-card class="pv-margin">
    <mat-card-title>Privacy Notice</mat-card-title>
    <p>
      Data protection is of a particularly high priority for our service. The use of the our website is possible
      without any indication of personal data; however, if a user registers an account, processing of
      personal data becomes necessary.
    </p>
    <p>
      The processing of personal data, such as your e-mail address shall always be in
      line with the General Data Protection Regulation (GDPR). By means of this privacy notice, we would
      like to inform you of the nature, scope, and purpose of the personal data we collect, use and process.
    </p>
    <mat-card-subtitle>Security Measures</mat-card-subtitle>
    <p>
      Your personal information is contained behind secured networks and is only accessible by a limited
      number of persons who have special access rights to such systems, and are required to keep the
      information confidential. In addition, all information you supply is encrypted via Secure Socket Layer
      (SSL) technology.
    </p>
    <mat-card-subtitle>Data</mat-card-subtitle>
    <p>
      We only collect data that is necessary for the provision of our service, such as your e-mail and parts
      of your connected Podio profile (e-mail, name, etc.). For paid subscriptions we collect payment data
      through Paddle (see “Third Parties”). Furthermore we collect data from Podio as requested or
      configured by the user to perform the validations.
    </p>
    <p>
      We do not use automatic decision-making or profiling.
    </p>
    <mat-card-subtitle>Third Parties</mat-card-subtitle>
    <p>
      To provide our service in a secure and reliable way, the following services are used:
    </p>

    <table class="pv-table pv-table-vtop pv-margin-b">
      <thead>
      <tr>
        <th>Company</th>
        <th>Address</th>
        <th>Service</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Google Ireland Limited</td>
        <td>Gordon House, Barrow Street, Dublin 4, Ireland</td>
        <td>Provisioning of infrastructure by the Google Compute Platform. All Data is stored in the EU.</td>
      </tr>
      <tr>
        <td>Plausible Insights OÜ</td>
        <td>Västriku tn 2, 50403, Tartu, Estonia</td>
        <td>Privacy first analytics services. No personally identifiable data (PII) is shared with this service.</td>
      </tr>
      <tr>
        <td>Bugsnag Inc.</td>
        <td>110 Sutter Street, San Francisco, CA 94104</td>
        <td>Error reporting and monitoring.</td>
      </tr>
      <tr>
        <td>Paddle.com Market Ltd.</td>
        <td>70 Wilson St, Finsbury, London EC2A 2DB, United Kingdom</td>
        <td>
          Our order process is conducted by our online reseller Paddle.com.
          Paddle.com is the Merchant of Record for all our orders.
          Paddle provides all customer service inquiries and handles returns.
        </td>
      </tr>
      </tbody>
    </table>

    <mat-card-subtitle>Your Rights</mat-card-subtitle>
    <p>
      You have the right to access your data, request data correction and erasure. Furthermore you can
      request a portable copy of the information you provided to us and you can withdraw any consent
      you gave us at any time. You can file a complaint with us or your local data protection authority at
      any time.
    </p>
    <p>
      Most of these actions you can perform through our website, for all other requests, please contact
      <a class="pv-link" href="mailto:info@vali-pod.io">info@vali-pod.io</a>
    </p>
    <mat-card-subtitle>Data Protection Officer</mat-card-subtitle>
    <p>
      For any concerns regarding this privacy policy, you can contact our Data Protection Officer:
    </p>
    <p>
      Daniel Schreiber<br/>
      Rohmerstr. 8<br/>
      60486 Frankfurt am Main<br/>
      Germany<br/>
      <a class="pv-link" href="mailto:info@vali-pod.io">info@vali-pod.io</a>
    </p>
  </mat-card>
  <mat-card class="pv-margin">
    <mat-card-title>Disclaimer</mat-card-title>
    <mat-card-subtitle>Accountability for content</mat-card-subtitle>
    <p>
      The contents of our pages have been created with the utmost care.
      However, we cannot guarantee the contents' accuracy, completeness or topicality. According to statutory
      provisions,
      we are furthermore responsible for our own content on these web pages. In this context, please note that
      we are
      accordingly not obliged to monitor merely the transmitted or saved information of third parties, or
      investigate
      circumstances pointing to illegal activity. Our obligations to remove or block the use of information
      under
      generally applicable laws remain unaffected by this as per &sect;&sect; 8 to 10 of the Telemedia Act
      (TMG).
    </p>
    <mat-card-subtitle>Accountability for links</mat-card-subtitle>
    <p>
      Responsibility for the content of external links (to web
      pages of third
      parties) lies solely with the operators of the linked pages. No violations were evident to us at the
      time of
      linking. Should any legal infringement become known to us, we will remove the respective link
      immediately.
    </p>
    <mat-card-subtitle>Copyright</mat-card-subtitle>
    <p>
      Our web pages and their contents are subject to German copyright law. Unless expressly permitted by law
      (&sect; 44a
      et seq. of the copyright law), every form of utilizing, reproducing or processing works subject to
      copyright
      protection on our web pages requires the prior consent of the respective owner of the rights. Individual
      reproductions of a work are allowed only for private use, so must not serve either directly or
      indirectly for
      earnings. Unauthorized utilization of copyrighted works is punishable (&sect; 106 of the copyright
      law).
    </p>
    <p>
      <i>Source: </i>
      <a class="pv-link" href="http://www.muster-vorlagen.net" target="_blank" rel="noopener">
        www.Muster-Vorlagen.net - Impressum Generator
      </a>
    </p>
  </mat-card>
</div>



