import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {UrlService} from '../shared/url.service';

@Component({
  selector: 'app-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginViewComponent implements OnInit {

  redirectAfterLoginUrl: string | null = null;

  redirectUrl = encodeURIComponent(`${this.urlService.baseUrl}/logged-in`);
  podioClientId = environment.podioClientId;

  constructor(private activatedRoute: ActivatedRoute, private urlService: UrlService) {
  }

  ngOnInit(): void {
    const redirectTo = this.activatedRoute.snapshot.queryParamMap.get('redirectTo');
    if (redirectTo) {
      this.redirectAfterLoginUrl = decodeURIComponent(redirectTo);
      // duplicate encoding for redirectTo url to assure "?" is handled correctly:
      this.redirectUrl = encodeURIComponent(`${this.urlService.baseUrl}/logged-in?redirectTo=${encodeURIComponent(this.redirectAfterLoginUrl)}`);
    }
  }

}
