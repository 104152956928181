<mat-toolbar>
  <button mat-icon-button *ngIf="(isDesktop$ | async) === false" class="pv-margin-r" (click)="snav.toggle()">
    <mat-icon *ngIf="!snav.opened">menu</mat-icon>
    <mat-icon *ngIf="snav.opened">close</mat-icon>
  </button>
  <a routerLink="/" class="logo pv-flex pv-text-link">
    <img src="assets/logo.svg" alt="vali pod logo">
    <span class="pv-muted pv-margin-b beta" *ngIf="!v1">beta</span>
  </a>
  <span class="pv-spacer"></span>
  <button mat-button (click)="logout()" *ngIf="user$ | async; else login">Logout</button>
  <ng-template #login>
    <a [href]="'https://podio.com/oauth/authorize?client_id='+podioClientId+'&redirect_uri='+redirectUrl+'&scope='"
       mat-flat-button color="primary">
      <img src="/assets/podio-icon.svg" class="podio-icon">
      Login with Podio
    </a>
  </ng-template>
</mat-toolbar>
<mat-sidenav-container style="min-height: 80%">
  <mat-sidenav [opened]="sideNavOpen$ | async" [mode]="(isDesktop$ | async) ? 'side' : 'over'" #snav>
    <mat-nav-list class="pv-copy">
      <a mat-list-item routerLink="/" queryParamsHandling="preserve" routerLinkActive="active"
         [routerLinkActiveOptions]="{exact: true}">Home</a>
      <ng-container *ngIf="user$ |async">
        <a mat-list-item routerLink="/app/select" queryParamsHandling="preserve" routerLinkActive="active">Select App</a>
        <a mat-list-item routerLink="/app/account" queryParamsHandling="preserve" routerLinkActive="active">Account</a>
      </ng-container>
      <a mat-list-item routerLink="/pricing" queryParamsHandling="preserve" routerLinkActive="active" *ngIf="featurePricing">Pricing</a>
      <a mat-list-item routerLink="/faq" queryParamsHandling="preserve" routerLinkActive="active">FAQs</a>
      <a mat-list-item routerLink="/support" queryParamsHandling="preserve" routerLinkActive="active">
        Feedback &amp; Support
      </a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLink="/privacy" routerLinkActive="active">Legal and Privacy Notice</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

