<div class="pv-margin pv-main pv-copy">
  <h1 class="pv-page-title">Feedback &amp; Support</h1>
  <mat-card>
    <mat-card-title>Feedback</mat-card-title>
    <p>
      <ng-container *ngIf="!v1">This product is in "beta" state, so not all features are available yet.</ng-container>
      We appreciate any feedback, to improve this service!
    </p>
    <p>
      If you miss specific features or want to check out the roadmap, please go to our
      <a href="https://productific.com/@ValiPod" class="pv-link" target="_blank" rel="noopener">
        feature page
        <mat-icon class="pv-muted" [inline]="true">launch</mat-icon>
      </a>.
      There you can vote for planned features that are important to you and create new feature requests.
      This helps us prioritizing the work on upcoming features.
    </p>
    <p>
      Follow us on Twitter (<a href="https://twitter.com/vali_pod" class="pv-link" target="_blank" rel="noopener">@vali_pod
      <mat-icon class="pv-muted" [inline]="true">launch</mat-icon>
    </a>) to receive updates for new features!
    </p>
    <mat-card-actions>
      <a mat-button href="https://productific.com/@ValiPod" target="_blank" rel="noopener">
        Feature page
        <mat-icon class="pv-muted" [inline]="true">launch</mat-icon>
      </a>
      <a mat-button href="https://twitter.com/vali_pod" target="_blank" rel="noopener">
        @vali_pod (Twitter)
        <mat-icon class="pv-muted" [inline]="true">launch</mat-icon>
      </a>
    </mat-card-actions>
  </mat-card>

  <mat-card class="pv-margin-t">
    <mat-card-title>Support</mat-card-title>
    <p>
      Please check out our <a class="pv-link" routerLink="/faq">FAQs</a>!
    </p>
    <p>
      For specific questions and problems with this service, please contact us by mail:
      <a class="pv-link" href="mailto:info@vali-pod.io">info@vali-pod.io</a>
    </p>
    <mat-card-actions>
      <a mat-button routerLink="/faq">FAQs</a>
      <a mat-button href="mailto:info@vali-pod.io">info@vali-pod.io</a>
    </mat-card-actions>
  </mat-card>

</div>



