import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AngularFireFunctions} from '@angular/fire/functions';

@Component({
  selector: 'app-manual-refresh-view',
  templateUrl: './manual-refresh-view.component.html',
  styleUrls: ['./manual-refresh-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManualRefreshViewComponent implements OnInit {

  appId: string | null;
  appItemId: string | null;
  error: string | null = null;

  constructor(private activatedRoute: ActivatedRoute,
              private fns: AngularFireFunctions,
              private cd: ChangeDetectorRef) {
  }

  async ngOnInit(): Promise<void> {
    this.appItemId = this.activatedRoute.snapshot.queryParamMap.get('item_id');
    this.appId = this.activatedRoute.snapshot.queryParamMap.get('app_id');

    if (!this.appId || !this.appItemId) {
      this.error = 'No app/item provided! Please check link or contact support!';
    } else {
      await this.fns.httpsCallable('manualItemRefresh')({app_id: this.appId, app_item_id: this.appItemId}).toPromise()
        .catch(e => {
          this.error = 'Trigger failed: please contact support!';
          this.cd.markForCheck();
        });
    }
  }

}
