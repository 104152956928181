<div class="pv-margin pv-main pv-copy">
  <h1 class="pv-page-title">Plans and Pricing</h1>

  <div class="plan-container pv-flex">
    <div></div>
    <mat-button-toggle-group [(ngModel)]="monthly" aria-label="monthly / yearly billing" class="month-year-toggle">
      <mat-button-toggle [value]="true">Monthly</mat-button-toggle>
      <mat-button-toggle [value]="false">
        Yearly <span class="pill">-16%</span>
      </mat-button-toggle>
    </mat-button-toggle-group>
    <div></div>
    <mat-card class="plan">
      <mat-card-title class="plan-name">Basic</mat-card-title>
      <div class="price">
        <div class="price-highlight">FREE</div>
        <div>
          forever
          <mat-icon class="pv-warn pv-inline-icon" [inline]="true">favorite</mat-icon>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="feature first"><strong>{{maxRulesFree}} rules</strong> per app</div>
      <div class="feature">Rules for attachments/files not supported</div>
      <div class="feature">All Podio organizations</div>
      <div class="feature">Basic support</div>
      <div class="trial">Forever free!</div>
      <div class="action" [ngSwitch]="plan$ | async">
        <div *ngSwitchCase="'FREE'" class="current-plan">Current plan</div>
        <a *ngSwitchCase="'NONE'" class="cta" [href]="'https://podio.com/oauth/authorize?client_id='+podioClientId+'&redirect_uri='+redirectUrl+'&scope='"
           mat-stroked-button color="primary">Login now!</a>
      </div>
    </mat-card>

    <mat-card class="plan">
      <mat-card-title class="plan-name">Standard</mat-card-title>
      <div class="price">
        <div class="price-highlight">{{monthly ? 5 : 50}} &euro;</div>
        <div>
          per {{monthly ? 'month' : 'year'}} and organization
          <ng-container *ngTemplateOutlet="orgHelp"></ng-container>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="feature first"><strong>{{maxRulesPaid}} rules</strong> per app</div>
      <div class="feature">Rules for <strong>attachments/files supported</strong></div>
      <div class="feature">
        Price per Podio organization
        <ng-container *ngTemplateOutlet="orgHelp"></ng-container>
      </div>
      <div class="feature">Prioritized <strong>support</strong></div>
      <div class="trial">14 days money back guarantee!</div>
      <div class="action" [ngSwitch]="plan$ | async">
        <div *ngSwitchCase="'STANDARD'" class="current-plan">
          <div *ngIf="(planMonthly$|async)===monthly; else: changeStandard" class="current-plan">Current plan</div>
          <ng-template #changeStandard>
            <a routerLink="/app/account" [queryParams]="{plan: 'STANDARD', monthly: monthly}" mat-stroked-button color="primary" class="cta">
              Change plan
            </a>
          </ng-template>
        </div>
        <a *ngSwitchCase="'NONE'" class="cta" [href]="'https://podio.com/oauth/authorize?client_id='+podioClientId+'&redirect_uri='+redirectUrl+'&scope='"
           mat-stroked-button color="primary">Try now!</a>
        <a routerLink="/app/account" [queryParams]="{plan: 'STANDARD', monthly: monthly}" mat-stroked-button color="primary" *ngSwitchDefault class="cta">
          Change plan
        </a>
      </div>
    </mat-card>

    <mat-card class="plan">
      <mat-card-title class="plan-name">Premium</mat-card-title>
      <div class="price">
        <div class="price-highlight">{{monthly ? 20 : 200}} &euro;</div>
        <div>per {{monthly ? 'month' : 'year'}}</div>
      </div>
      <mat-divider></mat-divider>
      <div class="feature first"><strong>{{maxRulesPaid}} rules</strong> per app</div>
      <div class="feature">Rules for <strong>attachments/files supported</strong></div>
      <div class="feature"><strong>Unlimited Podio organizations</strong></div>
      <div class="feature">Prioritized <strong>support</strong></div>
      <div class="trial">14 days money back guarantee!</div>
      <div class="action" [ngSwitch]="plan$ | async">
        <div *ngSwitchCase="'PREMIUM'">
          <div *ngIf="(planMonthly$|async)===monthly; else: changePremium" class="current-plan">Current plan</div>
          <ng-template #changePremium>
            <a routerLink="/app/account" [queryParams]="{plan: 'PREMIUM', monthly: monthly}" mat-stroked-button color="primary" class="cta">
              Change plan
            </a>
          </ng-template>
        </div>
        <a *ngSwitchCase="'NONE'" class="cta" [href]="'https://podio.com/oauth/authorize?client_id='+podioClientId+'&redirect_uri='+redirectUrl+'&scope='"
           mat-stroked-button color="primary">Try now!</a>
        <a routerLink="/app/account" [queryParams]="{plan: 'PREMIUM', monthly: monthly}" mat-stroked-button color="primary" *ngSwitchDefault class="cta">
          Change plan
        </a>
      </div>
    </mat-card>

    <div></div>
    <div class="support">
      <span class="pv-margin-r mat-body-strong">Any questions?</span>
      <a mat-stroked-button color="accent" routerLink="/support">
        <mat-icon>contact_support</mat-icon>
        Support
      </a>
    </div>
    <div></div>
  </div>


  <div class="pv-margin-v footnotes">
    <p>
      All prices exclude VAT/sales tax.
      You can upgrade/downgrade plans at any time.
    </p>
    <p class="pv-color-secondary">
      None of the plans fit your needs?
      Please reach out: <a class="pv-link" href="mailto:info@vali-pod.io">info@vali-pod.io</a>
    </p>
  </div>

</div>

<ng-template #orgHelp>
  <mat-icon class="pv-inline-icon pv-hand" [inline]="true"
            matTooltip="Once subscribed to this plan, organizations can be configured/added/removed on the account view at any time.">
    help
  </mat-icon>
</ng-template>
