<div class="pv-margin pv-flex pv-flex-col pv-flex-center pv-full-width">
  <h1 class="pv-page-title">Login</h1>

  <p *ngIf="redirectAfterLoginUrl" class="pv-margin">
    Please log in to access <a [routerLink]="redirectAfterLoginUrl" class="pv-link">{{redirectAfterLoginUrl}}</a>
  </p>

  <div class="pv-margin">
    <a [href]="'https://podio.com/oauth/authorize?client_id='+podioClientId+'&redirect_uri='+redirectUrl+'&scope='"
       mat-flat-button color="primary" class="pv-button-large">
      <img src="/assets/podio-icon.svg" class="podio-icon-large">
      Login with Podio
    </a>
  </div>

  <div class="pv-margin pv-flex pv-flex-col pv-flex-center">
    <p class="pv-muted">Need help?</p>
    <a routerLink="/support" mat-flat-button color="accent">Check support</a>
  </div>

</div>

