import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {isMultiAppReference, isMultiItemReference} from 'common-model/models';
import {PField} from 'common-model/podio-models';

@Component({
  selector: 'app-ref-help',
  templateUrl: './ref-help.component.html',
  styleUrls: ['./ref-help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RefHelpComponent implements OnInit {

  @Input()
  field: PField | null = null;

  constructor() {
  }

  ngOnInit(): void {
  }

  is(multiItem: boolean, multiApp: boolean): boolean {
    return !!this.field
      && (multiItem === isMultiItemReference(this.field))
      && (multiApp === isMultiAppReference(this.field));

  }
}
